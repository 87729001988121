import "./aboutme.css";
const AboutMe = () => {
  return (
    <div className="am_main">
      <div className="am_context">
        <a className="am_intro">About me</a>
        <p>
          I'm a senior at UC Irvine, graduating in June 2023. I decided to pursue
          software engineering, after earning my Psychology B.A. (also from UC
          Irvine, '19). During the pandemic, I decided to learn to code and I
          realized I wanted to make a career of it.
        </p>
        <p>
          Pursuing a career in software engineering after earning a degree in
          psychology was a huge change, however, a valuable combination. My
          degree in psychology helped provide a unique perspective on
          understanding user needs and design. While my software engineering
          skills allowed me to build and implement solutions to meet those
          needs.
        </p>
        <p>
          When I am not sitting in front of my computer, you can find me either
          somewhere in nature, at the gym, or taking pictures of my cats.
        </p>
      </div>
      <div className="am_img"></div>
    </div>
  );
};

export default AboutMe;
