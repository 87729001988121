import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import AboutMe from "./AboutMe/AboutMe";

const Routing = () => {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/aboutme" element={<AboutMe />} />
    </Routes>
  );
};

export default Routing;
