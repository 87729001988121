import React, { useState } from "react";
import { Switch } from "@mantine/core";
import { GoSun } from "react-icons/go";
import { IoMoonOutline } from "react-icons/io5";
import "./projects.css";
import "../Home.css";
import { useDarkMode } from "../DarkModeContext";

const Projects = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const projects = [
    {
      title: "Matchcards Game",
      to: "https://matchcard.cristyg.com/",
    },
    {
      title: "Slock",
      to: "https://slock.cristyg.com/",
    },
    {
      title: "Blender",
      to: "https://blender.cristyg.com/",
    },
    {
      title: "Mood Tracker",
      to: "https://github.com/cristy-codes/MoodTracker",
    },
  ];

  return (
    <div className={`main ${isDarkMode ? "dark" : ""}`}>
      <div className="title">
        Projects
        <Switch
          size="lg"
          checked={isDarkMode}
          onChange={toggleDarkMode}
          onLabel={<GoSun size={24} />}
          offLabel={<IoMoonOutline size={24} />}
          className="toggle-switch"
        />
      </div>
      <div className="main__projects">
        {projects.map((project) => {
          return (
            <a
              className={`card-i_item ${isDarkMode ? "dark-mode" : ""}`}
              href={project.to}
              target="_blank"
              rel="noreferrer noopener"
            >
              <a
                className={`card-i-item_title ${isDarkMode ? "dark-mode" : ""}`}
              >
                {project.title}
              </a>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
