import React, { useState } from "react";
import { Switch } from "@mantine/core";
import { GoSun } from "react-icons/go";
import { IoMoonOutline } from "react-icons/io5";
import "./Home.css";
import Pdf from "./Resume/ResumeJan2024.pdf";
import { useDarkMode } from "./DarkModeContext";

const links = [
  {
    name: "Resume",
    file: Pdf,
  },
  {
    name: "Projects",
    to: "/projects",
  },
  {
    name: "Linkedin",
    to: "https://www.linkedin.com/in/cristy-gonzales/",
  },
  {
    name: "Github",
    to: "https://github.com/cristy-codes",
  },
];

const Home = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <div className={`main ${isDarkMode ? "dark" : ""}`}>
      <div className="links_container">
        {links.map((link, idx) => (
          <a
            href={link.to || link.file}
            className={`links bounce-in-left__${idx + 1}`}
            key={idx}
          >
            {link.name}
          </a>
        ))}
      </div>
      <div className="main_context">
        <div className="name">
          Hi, I'm Cristy.
          <div className="text">
            A software engineer based in Los Angeles, CA 📍
          </div>
        </div>
        <div className="image" />
      </div>
      <div className="toggle-switch">
        <Switch
          size="lg"
          checked={isDarkMode}
          onChange={toggleDarkMode}
          onLabel={<GoSun size={24} />}
          offLabel={<IoMoonOutline size={24} />}
          className="toggle-switch"
        />
      </div>
    </div>
  );
};

export default Home;
